<script setup lang="ts">
const props = withDefaults(
	defineProps<{
		value?: number;
		maxValue?: number;
	}>(),
	{
		value: 0,
		maxValue: 100
	}
);
const pointsLeft = computed(() => props.maxValue - props.value);
const progressValue = computed(() => {
	if (pointsLeft.value === 0) {
		return "100%";
	}
	if (props.value !== 0) {
		return `${(props.value * 100) / props.maxValue}%`;
	}
	return "20px";
});
</script>

<template>
	<div class="progress-wrap">
		<NuxtIcon class="icon-money" name="12/secret-coins" filled />
		<div class="progress">
			<div class="progress-active" />
		</div>
		<AText type="x-small" class="value" :modifiers="['bold', 'uppercase']" as="div">
			{{ `${numberFormat(value)}/${numberFormat(maxValue)}` }}
		</AText>
	</div>
</template>

<style lang="scss" scoped>
.progress-wrap {
	position: relative;
	width: 100%;

	.progress {
		padding: 2px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		border-radius: 10px;
		background: var(--a-overlay-100);
		box-shadow: 0 -4px 0 0 var(--shadow-2) inset;

		&-active {
			width: v-bind(progressValue);
			height: 12px;
			background: #00b348;
			border-radius: 0 6px 6px 0;
			transition: all 500ms ease-in-out;
			min-width: 22px;
		}
	}

	.value {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		white-space: nowrap;
		line-height: 1;
	}

	.icon-money {
		display: flex;
		font-size: 18px;
		position: absolute;
		top: 50%;
		left: -1px;
		transform: translateY(-50%);

		&:deep(svg) {
			margin: 0;
		}
	}
}
</style>
